import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { default as MenuUrl } from "../../../context/url";

// 다국어 설정
import RegexText from "../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";

// Sub LNB
export default class introSubLnb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <div className='SubMainBox'>
                <div className='SubAllImg02'>
                  <div className='SubInner v-box'>
                    <div className='Menu h-box'>
                      <Link
                        to={MenuUrl.MAIN}
                        onClick={() => {
                          this.props.handleGnbMenu("main");
                        }}>
                        <div className='MenuIcon' />
                      </Link>
                      <div className='RightIcon' />
                      <div>
                        <span className='txt01'>{lc.header.intro}</span>
                      </div>
                      <div className='RightIcon' />
                      <div>{lc.subtitle[this.props.Titletext01]}</div>
                    </div>
                    <div className='MenuTitle'>
                      <span className='txt01'>{lc.header.intro}</span>
                    </div>
                    <div className='MenuText'>
                      <span className='txt02'>{lc.introduction.banner_title}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='SubLnbBox02'>
                <div className='SubInner02 v-box'>
                  <div className='SubMenu02'>
                    <ul className='OutMenu02 h-box'>
                      <Link
                        to={MenuUrl.INTRODUCTION_IN001}
                        onClick={() => {
                          this.props.handleGnbMenu("introduction", "1");
                        }}>
                        <li className={`OutMenuLi02 flex-1 ${this.props.introductionSub01 ? "on" : ""}`}>
                          <span className='txt02'>{lc.header.introService}</span>
                        </li>
                      </Link>
                      {/* <li className={`OutMenuLi02 flex-1 ${this.props.introductionSub03 ? 'on':''}`} onClick={()=>{window.open('https://help.douzone.com/counsel/counsel_write_pop.jsp?areaName=dt&eaddperson2=17');}}>도입문의</li> */}
                      <a
                        href={"https://help.douzone.com/counsel/counsel_write_pop.jsp?areaName=dt&eaddperson2=17"}
                        target='_blank'>
                        <li className={`OutMenuLi02 flex-1 ${this.props.introductionSub03 ? "on" : ""}`}>
                          <span className='txt02'>{lc.header.introContact}</span>
                        </li>
                      </a>
                      {/* <li className={`OutMenuLi02 ${this.props.introductionSub02 ? 'on':''}`} onClick={()=>{this.props.handleClickAlert();}}>요금안내</li> */}
                      {/* <li className={`OutMenuLi02 flex-1 ${this.props.introductionSub03 ? 'on':''}`} onClick={()=>{window.open('https://help.douzone.com/counsel/counsel_write_pop.jsp?areaName=dt&eaddperson2=17');}}>도입문의</li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}
