import React, { Component, Fragment } from "react";
import IntroSubLnb from "./unit/IntroSubLnb";
import { Link } from "react-router-dom";

import { default as MenuUrl } from "../../context/url";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 서비스유형
export default class IntroductionSub01 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.history) {
      this.props.handleGnbMenu("introduction", "1");
    }
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <IntroSubLnb
                introductionSub01={this.props.introductionSub01}
                introductionSub02={this.props.introductionSub02}
                introductionSub03={this.props.introductionSub03}
                handleGnbMenu={this.props.handleGnbMenu}
                Titletext01={this.props.Titletext01}
                handleClickAlert={this.props.handleClickAlert}
              />
              <div id='IntroductionSub01'>
                <div className='IntroMenu101'>
                  <div className='IntroTitle'>
                    <span className='txt01'>{lc.introduction_1.title_1}</span>
                  </div>
                  <div className='IntroBox h-box'>
                    <div className='IntroSmallBox h-box flex-1'>
                      <div className='LineBlue' />
                      <div className='SmallImg01' />
                      <div className='SmallBox'>
                        <div className='SmallTitle'>
                          <span className='txt02'>{lc.introduction_1.sub_title_1_1}</span>
                        </div>
                        <div className='SmallText'>
                          <span className='txt03'>{lc.introduction_1.sub_content_1_1}</span>
                        </div>
                      </div>
                    </div>
                    <div className='IntroSmallBox h-box flex-1'>
                      <div className='LineBlue' />
                      <div className='SmallImg02' />
                      <div className='SmallBox'>
                        <div className='SmallTitle'>
                          <span className='txt01'>{lc.introduction_1.sub_title_1_2}</span>
                        </div>
                        <div className='SmallText'>
                          <span className='txt02'>{lc.introduction_1.sub_content_1_2}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='IntroBG101' />
                </div>
                <div className='IntroMenu102'>
                  <div className='IntroTitle'>
                    <span className='txt01'>{lc.introduction_1.title_2}</span>
                  </div>
                  <div className='IntroBox h-box'>
                    <div className='IntroSmallBox'>
                      <div className='TitleBox h-box'>
                        <div className='AccImg' />
                        <div className='SmallTitle'>
                          <span className='txt02'>{lc.introduction_1.sub_title_2_1}</span>
                        </div>
                      </div>
                      <div className='TextBox'>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>01</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_2_1_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_2_1_1}</span>
                            </div>
                          </div>
                        </div>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>02</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_2_1_2}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_2_1_2}</span>
                            </div>
                          </div>
                        </div>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>03</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_2_1_3}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_2_1_3}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='IntroSmallBox'>
                      <div className='TitleBox h-box'>
                        <div className='AccImg' />
                        <div className='SmallTitle'>
                          <span className='txt02'>{lc.introduction_1.sub_title_2_2}</span>
                        </div>
                      </div>
                      <div className='TextBox'>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>01</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_2_2_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_2_2_1}</span>
                            </div>
                          </div>
                        </div>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>02</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_2_2_2}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_2_2_2}</span>
                            </div>
                          </div>
                        </div>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>03</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_2_2_3}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_2_2_3}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='IntroBG102' />
                </div>
                <div className='IntroMenu103'>
                  <div className='IntroTitle'>
                    <span className='txt01'>{lc.introduction_1.title_3}</span>
                  </div>
                  <div className='IntroBox h-box'>
                    <div className='IntroSmallBox'>
                      <div className='TitleBox h-box'>
                        <div className='AccImg' />
                        <div className='SmallTitle'>
                          <span className='txt02'>{lc.introduction_1.sub_title_3_1}</span>
                        </div>
                      </div>
                      <div className='IntroLine' />
                      <div className='TextBox'>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>01</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_3_1_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_1_1_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_1_1_2}</span>
                            </div>
                          </div>
                        </div>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>02</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_3_1_2}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_1_2_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_1_2_2}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='IntroSmallBox'>
                      <div className='TitleBox h-box'>
                        <div className='AccImg' />
                        <div className='SmallTitle'>
                          <span className='txt02'>{lc.introduction_1.sub_title_3_2}</span>
                        </div>
                      </div>
                      <div className='IntroLine' />
                      <div className='TextBox'>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>01</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_3_2_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_2_1_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_2_1_2}</span>
                            </div>
                          </div>
                        </div>
                        <div className='TextSmallBox'>
                          <div className='TextTitle h-box'>
                            <div className='ColorBlue'>02</div>
                            <div className='Ctitle'>
                              <span className='txt03'>{lc.introduction_1.sub_title_3_2_2}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_2_2_1}</span>
                            </div>
                          </div>
                          <div className='SmallTextBox h-box'>
                            <span className='Acc' />
                            <div className='SmallText'>
                              <span className='txt03'>{lc.introduction_1.sub_content_3_2_2_2}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='IntroBtnBox h-box'>
                    {/* <div className="IntroBtn01 h-box" onClick={() => { this.props.handleGnbMenu('introduction', '2'); }}>
                                <div className="BtnText">요금안내</div>
                                <div className="IntroArrow" />
                            </div> */}
                    <a
                      href='https://help.douzone.com/counsel/counsel_write_pop.jsp?areaName=dt&eaddperson2=17'
                      target={"_blank"}
                      className='IntroBtn02 h-box'>
                      <div className='BtnText'>
                        <span className='txt01'>{lc.introduction_1.button_1}</span>
                      </div>
                      <div className='IntroArrow' />
                    </a>
                    {/* <div className="IntroBtn02 h-box" onClick={()=>{window.open('https://help.douzone.com/counsel/counsel_write_pop.jsp?areaName=dt&eaddperson2=17');}}>
                                <div className="BtnText">도입문의</div>
                                <div className="IntroArrow"/>
                            </div> */}
                  </div>
                  <div className='IntroBG103' />
                  <div className='BGacc' />
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}
